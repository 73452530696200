import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './title.css'

const Title = ({ children, as = 'h1', size, uppercase }) => {
  return (
    <Wrapper as={as} size={size} uppercase={uppercase}>
      {children}
    </Wrapper>
  )
}

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  uppercase: PropTypes.bool,
}

export default Title
