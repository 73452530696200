import styled from 'styled-components'
import { accentColor, foregroundColor } from 'constants/theme'
import MEDIA from 'helpers/mediaTemplates'

export const Wrapper = styled.nav`
  ul {
    display: block;
    list-style: none;
    padding: 0;
    margin-top: 2rem;

    li {
      display: inline-block;
      margin-right: 4rem;
      line-height: 2;

      ${MEDIA.PHONE`
        display: block;
      `};

      &:last-child {
        margin-right: 0;
      }
    }
  }

  a {
    color: ${accentColor};
    font-weight: 300;
    text-decoration: none;
    position: relative;
    padding-bottom: 8px;
    transition-duration: 0.3s;

    &:hover {
      color: ${foregroundColor};
    }

    &:before {
      color: ${foregroundColor};
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      transition: width 0s ease, background 0.5s ease;
    }

    &:after {
      color: ${foregroundColor};
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background: ${accentColor};
      transition: width 0.5s ease;
    }

    &:hover:before {
      color: ${foregroundColor};
      width: 100%;
      background: ${foregroundColor};
      transition: width 0.5s ease;
    }

    &:hover:after {
      color: ${foregroundColor};
      width: 100%;
      background: transparent;
      transition: all 0s ease;
    }
  }
`
