import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './text.css'

const Text = ({ children, as = 'p', size }) => {
  return (
    <Wrapper as={as} size={size}>
      {children}
    </Wrapper>
  )
}

Text.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
}

export default Text
