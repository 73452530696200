import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './button.css'

const Button = props => <Wrapper {...props}>{props.children}</Wrapper>

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
