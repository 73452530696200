import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/layout'
import Box from 'components/box'
import Title from 'components/title'
import Text from 'components/text'
import Button from 'components/button'
import Nav from 'components/nav'
import { graphql } from 'gatsby'

const Index = ({ data }) => (
  <Layout>
    <Box>
      <Title as="h2" size="small" uppercase>
        <span>Technology</span> <span>+ Strategy</span> <span>+ Startups</span>
      </Title>
      <Text size="medium">{data.homeJson.content.childMarkdownRemark.rawMarkdownBody}</Text>
      <Button onClick={() => (window.location = 'mailto:hello@seankozer.com')}>Get in Touch</Button>
      <Nav />
    </Box>
  </Layout>
)

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Index

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`
