import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'

export const Wrapper = styled.h1`
  display: block;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '300'
      case 'medium':
        return '300'
      case 'small':
        return '700'
      default:
        return '500'
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem'
      case 'medium':
        return '2.6rem'
      case 'small':
        return '1.6rem'
      default:
        return '2rem'
    }
  }};
  text-transform: ${({ uppercase }) => () => {
    switch (uppercase) {
      case true:
        return 'uppercase'
      default:
        return 'none'
    }
  }};
  letter-spacing: ${({ uppercase }) => () => {
    switch (uppercase) {
      case true:
        return '0.15rem'
      default:
        return '0'
    }
  }};
  line-height: 1.8;
  margin-bottom: 2em;

  span {
    display: inline-block;
    line-height: 1;
  }

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem'
        case 'medium':
          return '2rem'
        case 'small':
          return '1.6rem'
        default:
          return '2rem'
      }
    }};
  `};
`
