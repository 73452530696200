import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'

export const Wrapper = styled.p`
  display: block;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '300'
      case 'medium':
        return '300'
      default:
        return '500'
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem'
      case 'medium':
        return '2.6rem'
      default:
        return '2rem'
    }
  }};
  line-height: 1.4;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem'
        case 'medium':
          return '2.4rem'
        default:
          return '2rem'
      }
    }};
  `};
`
