import styled from 'styled-components'
import { subtleAccentColor, foregroundColor } from 'constants/theme'

export const Wrapper = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 5px;
  color: ${foregroundColor};
  border: 1px solid ${foregroundColor};
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 4rem 0;
  padding: 2rem 3rem;
  text-transform: uppercase;
  transition: 0.3s background-color ease, 0.3s border-color ease;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${subtleAccentColor};
    border-color: ${subtleAccentColor};
    outline: none;
  }
`
