import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { Wrapper } from './nav.css'

const Nav = () => (
  <Wrapper>
    <ul>
      <li>
        <OutboundLink href="https://twitter.com/seankozer">Twitter</OutboundLink>
      </li>
      <li>
        <OutboundLink href="https://linkedin.com/in/seankozer">LinkedIn</OutboundLink>
      </li>
      <li>
        <OutboundLink href="https://medium.com/@skozer">Medium</OutboundLink>
      </li>
      <li>
        <OutboundLink href="http://www.howoldami.xyz/?utm_source=seankozer.com">
          Mobile App
        </OutboundLink>
      </li>
      <li>
        <a href="/~skate/index.htm">#skate</a>
      </li>
    </ul>
  </Wrapper>
)

export default Nav
